import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider, useUser } from './contexts/UserContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const Login = lazy(() => import('./components/Login'));
const AuthenticatedApp = lazy(() => import('./components/AuthenticatedApp'));

const theme = createTheme();

function AppContent() {
  const { checkAuth, authLoading, isAuthenticated } = useUser();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (authLoading) {
    return <CircularProgress />;
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/time/hour-logs" replace />} />
        <Route path="/*" element={isAuthenticated ? <AuthenticatedApp /> : <Navigate to="/login" replace />} />
      </Routes>
    </Suspense>
  );
}

function App() {
  return (
    <UserProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <AppContent />
          </LocalizationProvider>
        </ThemeProvider>
      </Router>
    </UserProvider>
  );
}

export default App;
