import { useEffect } from 'react';

// Custom event for profile picture updates
export const PROFILE_PICTURE_UPDATE_EVENT = 'profilePictureUpdate';

// Function to dispatch profile picture update event
export const notifyProfilePictureUpdate = (userId) => {
  const event = new CustomEvent(PROFILE_PICTURE_UPDATE_EVENT, {
    detail: { userId }
  });
  window.dispatchEvent(event);
};

// Hook to listen for profile picture updates
export const useProfilePictureUpdates = (callback) => {
  useEffect(() => {
    const handleUpdate = (event) => {
      callback(event.detail.userId);
    };

    window.addEventListener(PROFILE_PICTURE_UPDATE_EVENT, handleUpdate);
    return () => window.removeEventListener(PROFILE_PICTURE_UPDATE_EVENT, handleUpdate);
  }, [callback]);
};
